/* WARNING: this file is also used in app/components/photoBlur/templates/PhotoBlur.pug, app/components/photoBlur/views/PhotoBlurView.js */

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateZ(0);
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

/* LEVEL.BLURRED */
.blur1 {
  filter: blur(var(--themeBlurLite));
}

.doubleBlur.blur1 {
  filter: blur(calc(var(--themeBlurLite) * 2));
}

.cover.blur1,
.contain.blur1 {
  width: calc(100% + var(--themeBlurLite) * 2);
  height: calc(100% + var(--themeBlurLite) * 2);
  margin: calc(var(--themeBlurLite) * -1);
}

.doubleBlur.cover.blur1,
.doubleBlur.contain.blur1 {
  width: calc(100% + var(--themeBlurLite) * 4);
  height: calc(100% + var(--themeBlurLite) * 4);
  margin: calc(var(--themeBlurLite) * -2);
}

/* LEVEL.HIGHLY_BLURRED */
.blur2 {
  filter: blur(var(--themeBlurMedium));
}

.doubleBlur.blur2 {
  filter: blur(calc(var(--themeBlurMedium) * 2));
}

.cover.blur2,
.contain.blur2 {
  width: calc(100% + var(--themeBlurMedium) * 2);
  height: calc(100% + var(--themeBlurMedium) * 2);
  margin: calc(var(--themeBlurMedium) * -1);
}

.doubleBlur.cover.blur2,
.doubleBlur.contain.blur2 {
  width: calc(100% + var(--themeBlurMedium) * 4);
  height: calc(100% + var(--themeBlurMedium) * 4);
  margin: calc(var(--themeBlurMedium) * -2);
}
