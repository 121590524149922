.short {
  margin: 0 5px;
}

.normal {
  margin: 0 10px;
}

.large {
  margin: 0 20px;
}

.action:first-child {
  margin-left: 0;
}

.action:last-child {
  margin-right: 0;
}

.vertical {
  width: 100%;
}

.vertical.short {
  margin: 5px 0;
}

.vertical.normal {
  margin: 10px 0;
}

.vertical.large {
  margin: 20px 0;
}

.vertical.action:first-child {
  margin-top: 0;
}

.vertical.action:last-child {
  margin-bottom: 0;
}
