.bar {
  background-color: var(--themeCardLight);
  position: relative;
}

.light {
  background-color: var(--themeCard);
}

.inverse {
  background: var(--themeCardLightInverse);
}

.transparent {
  background-color: transparent;
}

.bar.overContent {
  border: none;
}

.overContent::after {
  content: '';
  position: absolute;
  background-color: var(--themeDivider);
  pointer-events: none;
  z-index: 10;
}

/* Positions */
.top {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom: 1px solid var(--themeDivider);
}

.left {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border-right: 1px solid var(--themeDivider);
}

.right {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-left: 1px solid var(--themeDivider);
}

.bottom {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top: 1px solid var(--themeDivider);
}

.overContent.top::after,
.overContent.bottom::after {
  height: 1px;
  left: 0;
  right: 0;
}

.overContent.left::after,
.overContent.right::after {
  width: 1px;
  top: 0;
  bottom: 0;
}

.overContent.top::after {
  bottom: 0;
}

.overContent.left::after {
  right: 0;
}

.overContent.right::after {
  left: 0;
}

.overContent.bottom::after {
  top: 0;
}
