.tab {
  position: relative;
  padding: 10px 0 8px; /* Since we have 2px border on bottom */
  height: 100%;
  cursor: pointer;
  color: var(--themeTextColor); /* By design it must be Flat button color */
  border: none;
  background: none;
  box-shadow: none;
  font: var(--themeH4);
  transition: color var(--themeTransitionEase) var(--themeTransitionSpeed), background var(--themeTransitionEase) var(--themeTransitionSpeed);
  display: flex;
}

.small {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tabInner {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.fullWidth {
  flex-grow: 1;
}

.fullHeight {
  height: 100%;
  display: flex;
}

.fullHeight .tabInner {
  height: 100%;
  align-items: center;
}

.fullWidth .tabInner {
  justify-content: center;
}

.equalWidth {
  flex: 1;
}

.disabled,
.active {
  cursor: default;
}

.prefix {
  margin-right: 10px;
}

.suffix {
  margin-left: 10px;
}

.floating {
  position: absolute;
  top: -10px;
}

.floatingSuffix {
  composes: floating;
  right: -20px;
}

.floatingPrefix {
  composes: floating;
  left: -20px;
}

.oneLine {
  white-space: nowrap;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
}
