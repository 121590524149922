.errorText {
  display: block;
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  margin-top: 4px;
  transform: translateY(20px);
  font: var(--themeTextSmall);
  color: var(--skinDanger);
  opacity: 0;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase);
  will-change: transform;
}

.errorText:empty {
  margin: 0;
}

.error.errorText {
  visibility: visible;
  max-height: 80px;
  transform: translateY(0);
  opacity: 1;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
}
