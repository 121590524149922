.list {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.fullWidth {
  display: flex;
}

.separator {
  min-width: 10px;
}

.fullWidth .separator {
  flex-grow: 1;
}

.tag {
  /* stylelint-disable-next-line declaration-no-important */
  flex-grow: 0 !important; /* Since based on compile order any other selector can become more important :( */
  margin-right: 10px;
  margin-left: 10px;
  user-select: none;
}

.tag:first-child {
  margin-left: 0;
}

.tag:last-child {
  margin-right: 0;
}
