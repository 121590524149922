.wrap {
  max-width: 100%;
}

.cover {
  composes: wrap;
  object-fit: cover;
}

.contain {
  composes: wrap;
  object-fit: contain;
}

.fullSize {
  width: 100%;
  height: 100%;
}
