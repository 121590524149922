.badge {
  border-radius: 50%;
  position: relative;
}

.badge::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: inherit;
  transform: translate(-50%, -50%);
  box-sizing: content-box;
}

.inverse::after {
  background: var(--skinSuccess);
}

/* Color modifications */
.success::after {
  background: var(--skinSuccess);
}

.danger::after {
  background: var(--skinDanger);
}

.primary::after {
  background: var(--skinPrimary);
}

.accent::after {
  background: var(--skinAccent);
}

.warning::after {
  background: var(--skinWarning);
}
