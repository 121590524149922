.link {
  font: var(--themeText);
  position: relative;
  vertical-align: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: var(--skinPrimary);
}

.hoverable:hover {
  text-decoration: underline;
}

.small {
  font: var(--themeTextSmall);
}

.inverse {
  color: var(--themeTextColorInverse);
}

.muted {
  color: var(--themeTextColorMuted);
}

.inherit {
  color: inherit;
}

.muted.inverse {
  color: var(--themeTextColorMutedInverse);
}

.underline {
  text-decoration: underline;
}

.hoverable.inverse:hover,
.hoverable.underline:hover {
  text-decoration: none;
}

.inline {
  display: inline;
}
