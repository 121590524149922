.iframe {
  display: block;
  width: 100%;
  height: 460px;
  border: none;
  padding-top: 60px;
  box-sizing: content-box;
}

.header {
  position: fixed;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 20px;
  border-bottom: 1px solid var(--themeDivider);
  background-color: var(--themeCard);
}
