.ripple {
  display: block;
  position: absolute;
  z-index: 100;
  transform: scale(0);
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}

.inverse {
  background: rgba(255, 255, 255, 0.1);
}

.animating {
  animation: ripple 750ms linear;
}

.container {
  display: block;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
