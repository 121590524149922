.block {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  margin: 0;
}

.rounded {
  border-radius: var(--themeRadius);
}

.size40 {
  width: 40px;
  height: 40px;
}

.size60 {
  width: 60px;
  height: 60px;
}

.size80 {
  width: 80px;
  height: 80px;
}

.size120 {
  width: 120px;
  height: 120px;
}

.size200 {
  width: 200px;
  height: 200px;
}

.square {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  border-radius: inherit;
}

.square .block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flexible {
  width: 100%;
  height: 100%;
  padding: 0;
}
