.radio {
  display: flex;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  user-select: none;
  justify-content: flex-start;
}

.verticalTop {
  align-items: flex-start;
}

.verticalCenter {
  align-items: center;
}

/* Elements */
.input {
  /* Real checkbox or radio box. Must be hidden */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  cursor: pointer;
}

.icon {
  display: flex;
  position: relative;
  z-index: 2;
  background: transparent;
  color: var(--themeIcon);
  transition: var(--themeTransition);
}

.position {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 5px;
}

.wrap {
  position: relative;
}

.margin {
  margin-right: 20px;
}

.short {
  margin-right: 10px;
}

.flexibleWidth {
  flex-grow: 1;
}

.background .inverse .icon {
  color: var(--themeIconInverse);
}

.background .label {
  text-align: center;
}

.container {
  position: relative;
  flex-shrink: 0;
}

/* States */
.active .icon {
  color: var(--skinPrimary);
}

/* translateZ(0) is fix for render on iphone SE */
.active .icon::after {
  transform: translateZ(0);
}

/* Disabled state */
.disabled {
  pointer-events: none;
  cursor: default;
}

.disabled .icon {
  color: var(--themeIconDisabled);
}

.disabled:active .container {
  display: none;
}

/* Inverse state */
.inverse {
  composes: radio;
}

.inverse .icon {
  color: var(--themeIconDisabledInverse);
}

.inverse.disabled .icon {
  opacity: 0.5;
}

.inverse.active .icon {
  color: var(--themeIconInverse);
}

.inverse.disabled.active .icon {
  opacity: 1;
}
