.wrap {
  display: flex;
}

.inline {
  display: inline-flex;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.between {
  justify-content: space-between;
}

.vertical {
  display: block;
}

.verticalCenter {
  align-items: center;
}

.verticalTop {
  align-items: flex-start;
}

.verticalBottom {
  align-items: flex-end;
}

.icon {
  display: flex;
  flex-shrink: 0;
}

.text {
  overflow: hidden;
}
