.container {
  position: relative;
}

.imageWrap {
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--themeCardLight);
  border-radius: var(--themeRadius);
}

.highlighted {
  background-color: var(--skinPrimaryLite);
}

.icon {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
