.stretch {
  flex-grow: 1;
}

.spacing:empty {
  display: none;
}

.shortest {
  padding: 4px; /* Used for spacing between text, icons, etc. */
}

.short {
  padding: 10px;
}

.normal {
  padding: 20px;
}

.large {
  padding: 40px;
}

.vertical {
  padding-left: 0;
  padding-right: 0;
}

.horizontal {
  padding-top: 0;
  padding-bottom: 0;
}

/**
 * Indicates, that padding will be applied only on back side of item
 * if it's first or last in container
 */
.oneSided:last-child {
  padding-bottom: 0;
}

.oneSided:first-child {
  padding-top: 0;
}

.vertical.oneSided:last-child {
  padding-left: 0;
}

.vertical.oneSided:first-child {
  padding-right: 0;
}

.withoutTop {
  padding-top: 0;
}

.withoutBottom {
  padding-bottom: 0;
}

.withoutLeft {
  padding-left: 0;
}

.withoutRight {
  padding-right: 0;
}

/**
 * Yep, for avoiding writing massive ternanry operators in react components,
 * we know, that all 'large' spacings must became 'normal'.
 */
@media (max-width: 767px) {
  .adaptive.large {
    padding: 20px;
  }

  .adaptive.large.withoutTop {
    padding-top: 0;
  }

  .adaptive.large.withoutBottom {
    padding-bottom: 0;
  }

  .adaptive.large.withoutLeft {
    padding-left: 0;
  }

  .adaptive.large.withoutRight {
    padding-right: 0;
  }

  .adaptive.large.vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .adaptive.large.horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .adaptive.oneSided:last-child {
    padding-bottom: 0;
  }

  .adaptive.oneSided:first-child {
    padding-top: 0;
  }

  .adaptive.vertical.oneSided:last-child {
    padding-left: 0;
  }

  .adaptive.vertical.oneSided:first-child {
    padding-right: 0;
  }
}
