.wrapper {
  background: var(--themeInputBorder);
  border-radius: var(--themeRadius);
}

.bar {
  width: 0;
  height: 4px;
  border-radius: var(--themeRadius);
}

.wrapper.inverse {
  background: var(--themeDividerInverse);
}

.wrapper.inverse .bar {
  background: var(--themeTextColorInverse);
}

/* Color modifications */
.success {
  background: var(--skinSuccess);
}

.danger {
  background: var(--skinDanger);
}

.primary {
  background: var(--skinPrimary);
}

.accent {
  background: var(--skinAccent);
}

.warning {
  background: var(--skinWarning);
}

.headerColor {
  background: var(--skinHeader);
}
