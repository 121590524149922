.input {
  position: relative;
  text-align: left; /* All content inside must be left aligned, everywhere */
}

.input :global(.hideCaret) {
  caret-color: transparent; /* To fix the bug of another div overlapping the caret on iOS. @see useElementPositionAboveKeyboard */
}

/* rtl:begin:ignore */
.value {
  /* Real input */
  width: 100%;
  min-height: 40px;
  padding: 10px 0;
  font: var(--themeFontSize) / 20px var(--themeFontFamily);
  text-overflow: ellipsis;
  appearance: none;
  color: var(--themeTextColor);
  -webkit-text-fill-color: var(--themeTextColor); /* For iOS appearance is not enough */
  opacity: 1; /* For iOS too. As above */
  border: 0;
  background: none;
  box-shadow: none;
  flex-grow: 1;
  transition: var(--themeTransition);
  outline: 0;
  resize: none;
}

/* disable autofill password in Safari */
.value::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  top: -50px;
}

.value:-webkit-autofill,
.value:-webkit-autofill:hover,
.value:-webkit-autofill:focus,
.value:-webkit-autofill:active {
  -webkit-text-fill-color: var(--themeTextColor);
  box-shadow: inset 0 0 0 40px var(--skinPrimaryLite);
  border-radius: inherit;
}

.value:-webkit-autofill {
  background: none !important; /* stylelint-disable-line */
  color: var(--themeTextColor) !important; /* stylelint-disable-line */
}

.unspaced .value {
  min-height: 20px;
  padding: 0;
}

/* rtl:end:ignore */

.value::placeholder {
  color: var(--themeTextColorDisabled);
  -webkit-text-fill-color: var(--themeTextColorDisabled); /* For iOS */

  /* to fix dirty overflowed placeholders in textarea */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value:disabled {
  pointer-events: none;
  cursor: default;
}

.value:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.value:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.cvv {
  /* Show dots in Chrome on cvv */
  -webkit-text-security: disc;
}

.description,
.successText {
  display: block;
  overflow: hidden;
  max-height: 45px; /* We suppose, that in input will be only 2 rows and no more */
  margin-top: 2px;
  will-change: transform;
  font: var(--themeTextSmall);
}

.description {
  transform: translateY(0);
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
  color: var(--themeTextColorMuted);
}

.successText {
  visibility: hidden;
  max-height: 0;
  margin-top: 3px; /* As on BB - bigger spacing */
  transform: translateY(20px);
  opacity: 0;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase);
  color: var(--skinSuccess);
}

.successText:empty {
  margin: 0;
}

/* Prefix and sufix for phone codes etc. */
.prefix {
  padding: 10px 0;
  line-height: 20px;
  margin-right: 10px;
}

.container {
  transition: var(--themeTransition);
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
}

.hoverable {
  will-change: transform; /* Fix disappearing border in IOS popup when scrolling */
}

.icon {
  display: inline-flex;
  color: var(--themeIcon);
  transition: var(--themeTransition);
  padding: 0 0 0 20px;
  user-select: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  flex-shrink: 0;
}

.icon:first-child {
  /* If icon is before input - it's a static icon */
  padding-right: 20px;
  padding-left: 0;
  color: var(--themeIcon);
}

/* Global states */
.success .iconSuccess {
  font-size: 20px;
}

.success .successText {
  visibility: visible;
  max-height: 45px;
  transform: translateY(0);
  opacity: 1;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
}

.success .description,
.error .description {
  visibility: hidden;
  max-height: 0;
  transform: translateY(20px);
  opacity: 0;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase);
}

/* Disabled state */
.disabled {
  pointer-events: none;
}

.disabled .value {
  color: var(--themeTextColorMuted);
  -webkit-text-fill-color: var(--themeTextColorMuted);
}

/* nonInteractive state */
.nonInteractive {
  pointer-events: none;
}

.nonInteractive .container {
  opacity: 1;
}

/**
 * Modifications
 */

/* Label and input in one line */
.inlineLabel {
  display: flex;
  align-items: center;
}

.inlineLabel .label {
  margin-right: 10px;
}

/* Clickable input */
.clickable {
  cursor: pointer;
}

/* animation for input with label */
.animated {
  padding-top: 20px;
}

.animated .prefix,
.animated .sufix,
.animated .value::placeholder {
  opacity: 0;
  transition: opacity 65ms var(--themeTransitionEase);
}

.animated.hasValue .prefix,
.animated.focused .prefix,
.animated.hasValue .sufix,
.animated.focused .sufix,
.animated .value:focus::placeholder {
  color: inherit;
  transition: opacity var(--themeTransitionSpeed) var(--themeTransitionEase) 125ms;
  opacity: 1;
}

/* Not bordered modification (used on search form WEB) */
.fullSize {
  height: 100%;
}

.fullSize .container {
  height: 100%;
  border: none;
}

/* Inverse modification for input */
.inverse .icon:first-child {
  color: var(--themeIconDisabledInverse);
}

.inverse .value {
  color: var(--themeTextColorInverse);
  -webkit-text-fill-color: var(--themeTextColorInverse);
}

.inverse .value::placeholder {
  color: var(--themeTextColorDisabledInverse);
  -webkit-text-fill-color: var(--themeTextColorDisabledInverse); /* For iOS */
}

/* stylelint-disable */
.value:-webkit-autofill::placeholder,
.animated .value:-webkit-autofill::placeholder,
.inverse .value:-webkit-autofill::placeholder,
.input:not(.hasValue):has(.container .value:-webkit-autofill) > .label {
  color: transparent;
}
/* stylelint-enable */

.inverse .container {
  background-color: var(--themeInputBackgroundInverse);
  border-color: var(--themeInputBorderInverse);
}

.inverse .description {
  color: var(--themeTextColorMutedInverse);
}

.inverse .icon {
  color: var(--themeIconInverse);
}
