.heading {
  color: var(--themeTextColor);
  margin: 0;
  padding: 0;
}

.heading:first-child {
  margin-top: 0;
}

.heading:last-child {
  margin-bottom: 0;
}

.spaced {
  margin: 20px 0;
}

.inline {
  display: inline;
}

.muted {
  color: var(--themeTextColorMuted);
}

/**
 * @deprecated Remove "highlighted" property
 */
.primary,
.highlighted {
  color: var(--skinPrimary);
}

.danger {
  color: var(--skinDanger);
}

.warning {
  color: var(--skinWarning);
}

.success {
  color: var(--skinSuccess);
}

.inverse {
  color: var(--themeTextColorInverse);
}

.inverse.muted {
  color: var(--themeTextColorMutedInverse);
}

.inherit {
  color: inherit;
}

.h1 {
  font: var(--themeH1);
}

.h2 {
  font: var(--themeH2);
}

.h3 {
  font: var(--themeH3);
}

.h4 {
  font: var(--themeH4);
}

.h5 {
  font: var(--themeH5);
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}
