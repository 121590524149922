/* Badges, counters */
.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1 0;
  vertical-align: top; /* Fixes block height */
  position: relative;
  min-width: 20px;
  font: var(--themeTextSmall);
  -webkit-text-fill-color: currentColor;
  border-radius: var(--themeRadiusSmall);
  padding: 2px 7px;
  transition: var(--themeTransition);
}

.withIcon {
  padding-left: 5px;
}

.tag.large {
  font: var(--themeText);
  padding: 10px 16px;
}

.withIcon.large {
  padding-left: 10px;
}

.tag:empty {
  display: none; /* Hide container if there is no counters */
}

.largeRadius {
  border-radius: var(--themeRadiusLarge);
}

.icon {
  padding-right: 4px;
  position: relative;
  z-index: 2;
}

.large .icon {
  padding-right: 10px;
}

.onlyIcon {
  width: 20px;
  height: 20px;
  padding: 0;
}

.onlyIcon .icon {
  padding: 0;
}

.onlyIcon.large {
  width: 40px;
  height: 40px;
}

.onlyIcon.large .icon {
  padding: 0;
}

/* Inverse colors for tags on active elements */
.inverse {
  background: var(--themeCard);
}

.inverse.default {
  color: var(--themeTag);
}

.inverse.primary {
  color: var(--skinPrimary);
}

.inverse.accent {
  color: var(--skinAccent);
}

.inverse.success {
  color: var(--skinSuccess);
}

.inverse.danger {
  color: var(--skinDanger);
}
