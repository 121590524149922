.galleryCarousel {
  position: relative;
  display: flex;
  user-select: none;
  line-height: 0;
  border-radius: inherit;
  -webkit-tap-highlight-color: transparent;
}

.overflow {
  overflow: hidden;
}

.container {
  border-radius: inherit;
}

.fullSize {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.slidesContainer {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  border-radius: inherit;

  /* translateZ(0) is fix for render on iphone SE */
  transform: translateZ(0);
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.isDragging {
  pointer-events: none;
}

/* Gallery render 3 items (prev, current, next)
 Need for auto height current slide */
.slide:nth-child(2) {
  position: relative;
}

.fullSize .slide:nth-child(2) {
  position: absolute;
}

.slide:focus {
  outline: 0;
}

/* Fix centering image in PhotoZoomableLayout */
.slide img {
  width: 100%;
}
