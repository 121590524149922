.item {
  padding: 0;
}

.shortest {
  margin-bottom: 4px;
}

.short {
  margin-bottom: 10px;
}

.normal {
  margin-bottom: 20px;
}

.large {
  margin-bottom: 40px;
}

.item:last-child {
  margin-bottom: 0;
}

.bullet {
  float: left;
}

.content {
  display: block;
}

.horizontalShortest {
  margin-left: 4px;
}

.horizontalShort {
  margin-left: 10px;
}

.horizontalNormal {
  margin-left: 20px;
}

.horizontalLarge {
  margin-left: 40px;
}

.content:first-child {
  margin-left: 0;
}

/**
 * Yep, for avoiding writing massive ternary operators in react components,
 * we know, that all 'large' spacings must became 'normal'.
 */
@media (max-width: 767px) {
  .large {
    margin-bottom: 20px;
  }
}
