.menu {
  padding: 10px 0;
  position: relative;
  z-index: 5;
}

.fullHeight {
  height: 100%;
  padding: 0;

  /* Prevent collapsing popover menu when we rotate phone and web visualization appeared */
  max-height: calc(var(--appHeight) * 0.65);
  overflow-y: auto;
}
