.card {
  color: var(--themeTextColor);
  border-radius: var(--themeRadius);
  background: var(--themeCard);
  max-width: 100%;
}

.card::after {
  /**
   * Imitation of old and good "clearfix" for avoiding
   * loosing last child margins if ther exists. E.g.: "Card" with nested "Bar" component
   */
  content: "";
  display: table;
}

.card:empty {
  display: none;
}

.inverse {
  color: var(--themeTextColorInverse);
}

.light {
  background: var(--themeCardLight);
}

.highlighted {
  background: var(--skinPrimaryLite);
}

.saturated.highlighted {
  background: var(--skinPrimary);
}

.success {
  background: var(--skinSuccessLite);
}

.success.highlighted {
  color: var(--skinSuccess);
}

.saturated.success {
  background: var(--skinSuccess);
}

.danger {
  background-color: var(--skinDangerLite);
}

.danger.highlighted {
  color: var(--skinDanger);
}

.saturated.danger {
  background: var(--skinDanger);
}

.accent {
  background: var(--skinAccentLite);
}

.accent.highlighted {
  color: var(--skinAccent);
}

.saturated.accent {
  background: var(--skinAccent);
}

.header {
  background: var(--skinHeader);
}

.transparent {
  background: transparent;
}

.boundless {
  border-radius: inherit;
}

.overflow {
  overflow: hidden;
}

/* Shadow levels for card */
.shadow0 {
  box-shadow: none;
}

.shadow1 {
  box-shadow: var(--themeShadow1);
}

.shadow2 {
  box-shadow: var(--themeShadow2);
}

.shadow3 {
  box-shadow: var(--themeShadow3);
}

.shadow4 {
  box-shadow: var(--themeShadow4);
}
