.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.withMinSize {
  min-width: 20px;
  min-height: 20px;
}

.verticalTop {
  align-items: flex-start;
}

.verticalCenter {
  align-items: center;
}

/* Elements */
.input {
  /* Real checkbox or radio box. Must be hidden */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  cursor: pointer;
}

.icon {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  font-size: 24px;
  background: transparent;
  color: var(--themeIcon);
  transition: var(--themeTransition);
}

.labelWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  width: 80px;
}

.container .label {
  text-align: center;
  word-wrap: break-word;
}

.position {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 5px;
}

.wrap {
  position: relative;
}

.background .wrap {
  width: 100%;
  margin-bottom: 4px;
}

.background {
  flex-direction: column;
  width: 80px;
}

.fullWidthBackground {
  width: 100%;
}

.background .label {
  width: 100%;
  text-align: center;
  word-break: break-word;
  word-wrap: break-word; /* EDGE */
}

.background .margin {
  margin-right: 0;
}

.background .inverse .icon {
  color: var(--themeIconInverse);
}

.margin {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}

.iconContainer {
  position: relative;
  width: 20px;
  height: 20px;
}

.active .icon {
  color: var(--skinPrimary);
}

.muted .icon,
.muted.active .icon {
  color: var(--themeIconMuted);
}

/* Disabled state */
.disabled {
  cursor: default;
  pointer-events: none;
}

.disabled .icon {
  color: var(--themeIconDisabled);
}

.disabled.active .icon,
.disabled.muted.active .icon {
  color: var(--themeIconDisabled);
}

.inline {
  display: inline-flex;
}

/* Inverse state */
.inverse {
  composes: checkbox;
}

.inverse .icon {
  color: var(--themeIconDisabledInverse);
}

.inverse.active .icon {
  color: var(--themeIconInverse);
}

.inverse.active.muted .icon {
  color: var(--themeIconMutedInverse);
}

.inverse.disabled .icon {
  opacity: 0.5;
  color: var(--themeIconDisabledInverse);
}

.inverse.disabled.active .icon,
.inverse.active.muted .icon {
  color: var(--themeIconDisabledInverse);
  opacity: 1;
}

/* When action item must be aligned. Default is center */
.top {
  composes: checkbox;
  align-items: flex-start;
}

.top .iconContainer {
  padding-top: 2px;
}

.center {
  composes: checkbox;
  align-items: center;
}

.bottom {
  composes: checkbox;
  align-items: flex-end;
}

.bottom .iconContainer {
  padding-bottom: 2px;
}

/* Size modifications */
.small {
  flex-shrink: 0;
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
}

.small .iconContainer {
  width: 16px;
  height: 16px;
}

.small .icon {
  font-size: 16px;
}

.smallest {
  flex-shrink: 0;
  min-width: 12px;
  min-height: 12px;
}

.smallest .iconContainer {
  width: 12px;
  height: 12px;
}

.smallest .icon {
  font-size: 12px;
}

.error {
  margin-top: 10px;
}
