.label {
  font: var(--themeText);
  color: var(--themeTextColorLabel);

  /* translateZ(0) is fix for render on iphone SE */
  transform: translateZ(0);
}

.inverse.label {
  color: var(--themeTextColorLabelInverse);
}

.small {
  composes: label;
  padding-top: 4px;
  font: var(--themeTextSmall);
}

.inline {
  display: inline;
  padding: 0;
}

.flexibleWidth {
  flex-grow: 1;
}

.animated {
  font: var(--themeTextSmall);
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  pointer-events: none;
  transition: var(--themeTransition);
  transition-property: transform, font, right;
  will-change: transform, font-size, right;

  /* Because some themes can have additional spacings, paddings, etc. */
  max-width: 90%;
}

.withRightIcon.animated.hasValue,
.withRightIcon.animated.focused {
  right: 0;
}

.error {
  color: var(--skinDanger);
}

.hasValue,
.focused {
  max-width: 100%;
}

.focused {
  color: var(--skinPrimary);
}

.animated.error {
  color: var(--themeTextColorLabel);
}

.animated.error.hasValue {
  color: var(--skinDanger);
}

.animated.focused,
.animated.error.focused {
  color: var(--skinPrimary);
}
