.withOverlay::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.95;
  background: var(--themeCard);
  border-radius: inherit;
}

.round {
  border-radius: var(--themeRadiusLarge);
}

.progress {
  position: relative;
  height: 100%;
  width: 100%;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  height: 100%;
}

.fixedSize {
  max-width: 80px;
  max-height: 80px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.percentage {
  composes: centered;
  z-index: 2;
  font: var(--themeH2);
}

/* Percentage color modifications */
.primary .percentage {
  color: var(--skinPrimary);
}

.danger .percentage {
  color: var(--skinDanger);
}

.success .percentage {
  color: var(--skinSuccess);
}

.accent .percentage {
  color: var(--skinAccent);
}

.warning .percentage {
  color: var(--skinWarning);
}

.headerColor .percentage {
  color: var(--skinHeader);
}

.inverse .percentage {
  color: var(--themeTextColorInverse);
}

.inherit .percentage {
  color: inherit;
}
