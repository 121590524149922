.overlay {
  position: relative;
  color: var(--themeTextColorInverse);
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-grow: 1;
}

.overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  transform: none;
  border-radius: inherit;

  /* Don't block with this overlay clicks under it */
  pointer-events: none;
}

.fullSize {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Position */
.top {
  bottom: auto;
}

.bottom {
  top: auto;
}

.left {
  right: auto;
}

.right {
  left: auto;
}

/* Saturation */
.extraLight::after {
  background: var(--themeOverlayExtraLite);
}

.light::after {
  background: var(--themeOverlayLite);
}

.medium::after {
  background: var(--themeOverlayMedium);
}

.heavy::after {
  background: var(--themeOverlayHeavy);
}

.inverse.heavy::after {
  background: var(--themeOverlayHeavyInverse);
}

.inverse.medium::after {
  background: var(--themeOverlayMediumInverse);
}

.inverse.light::after {
  background: var(--themeOverlayLiteInverse);
}

.inverse.extraLight::after {
  background: var(--themeOverlayExtraLiteInverse);
}

.gradientFromBottom::after {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.inverse.gradientFromBottom::after {
  background: linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.gradientFromTop::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.inverse.gradientFromTop::after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.light.gradientFromTop::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
}

.light.inverse.gradientFromTop::after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0));
}

.gradientTopAndBottom::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5) 100%);
}

.transparent::after {
  background: none;
}
