.attached {
  display: block;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  transition: var(--themeTransition);
  text-align: left;
  width: 100%;
}

.wrap {
  display: flex;
  align-items: center;
  height: inherit;
}

/* Custom margins because of icons sizes to make visual alignment of text and icons in different banners */
.icon.left {
  margin: 0 18px 0 -2px;
}

/* Custom margins because of icons sizes to make visual alignment of text and icons in different banners */
.icon.right {
  order: 1;
  margin: 0 -2px 0 18px;
}
