.item {
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--themeTextColor);
  -webkit-text-fill-color: currentColor;
  width: 100%;
  background: transparent;
  text-align: left;
  transition: background var(--themeTransitionSpeed) var(--themeTransitionEase);
  font: var(--themeFontSize) / 20px var(--themeFontFamily);
}

.item:hover,
.item.active {
  color: var(--themeTextColor);
  background: var(--themeCardLight);
}

.spaced {
  padding-right: 40px;
  padding-left: 40px;
}

/* Remove hover highlighting for touch devices */
.item.touch:hover {
  background: none;
}

.item.touch.active {
  background: var(--themeCardLight);
}

.disabled:hover,
.disabled {
  cursor: default;
  color: var(--themeTextColorMuted);
  background: transparent;
}

.hidden {
  display: none;
}

.muted:hover,
.muted.active,
.muted {
  color: var(--themeTextColorMuted);
}

/* Inverse solution */
.inverse {
  color: var(--themeTextColorInverse);
}

.inverse:hover,
.inverse.active {
  color: var(--themeTextColorInverse);
  background: var(--themeCardLightInverse);
}

.inverse.touch:hover {
  background: none;
}

.inverse.touch.active {
  background: var(--themeCardLightInverse);
}

.inverse.disabled:hover,
.inverse.disabled {
  cursor: default;
  color: var(--themeTextColorMutedInverse);
  background: transparent;
}

.inverse.muted:hover,
.inverse.muted {
  color: var(--themeTextColorMutedInverse);
}

/* Common styles */
.static:hover,
.static {
  cursor: default;
  background: transparent;
}

.nowrap {
  white-space: nowrap;
}
