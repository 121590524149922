.icon {
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inline {
  display: inline-flex;
}

.wrap {
  flex-shrink: 0;
}

.round {
  border-radius: var(--themeRadiusLarge);
}

/* Type modifications */
.primary {
  color: var(--skinPrimary);
  background: var(--skinPrimaryLite);
}

.accent {
  color: var(--skinAccent);
  background: var(--skinAccentLite);
}

.danger {
  color: var(--skinDanger);
  background: var(--skinDangerLite);
}

.translucent {
  color: var(--themeIconInverse);
  background: var(--themeTagTranslucent);
}

.muted {
  color: var(--themeIcon);
  background: var(--themeButton);
}

.success {
  color: var(--skinSuccess);
  background: var(--skinSuccessLite);
}

.warning {
  color: var(--skinWarning);
  background: var(--skinWarningLite);
}

/* Saturated */
.saturated.primary {
  color: var(--skinPrimaryLite);
  background: var(--skinPrimary);
}

.saturated.accent {
  color: var(--skinAccentLite);
  background: var(--skinAccent);
}

.saturated.danger {
  color: var(--skinDangerLite);
  background: var(--skinDanger);
}

.saturated.success {
  color: var(--skinSuccessLite);
  background: var(--skinSuccess);
}

.saturated.warning {
  color: var(--skinWarningLite);
  background: var(--skinWarning);
}

/* Inverse saturated */
.saturated.inverse.primary {
  color: var(--skinPrimary);
  background: var(--skinPrimaryLite);
}

.saturated.inverse.accent {
  color: var(--skinAccent);
  background: var(--skinAccentLite);
}

.saturated.inverse.danger {
  color: var(--skinDanger);
  background: var(--skinDangerLite);
}

.saturated.inverse.success {
  color: var(--skinSuccess);
  background: var(--skinSuccessLite);
}

.saturated.inverse.warning {
  color: var(--skinWarning);
  background: var(--skinWarningLite);
}
