.container {
  max-width: 1020px;
  width: 100%;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.container.unspaced {
  padding: 0;
}
