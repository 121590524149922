.content {
  position: relative;
  text-transform: none;
  color: var(--themeTextColor);
  border-radius: var(--themeRadius);
  font: var(--themeText);
}

.inverse {
  color: var(--themeTextColorInverse);
}

.content:empty {
  display: none;
}

.wrap {
  border-radius: inherit;
  position: relative;
  z-index: 3;
}

.fixedHeight .content {
  /* fix overlaps scrollbar for rounded menu */
  overflow: hidden;
}

.fixedHeight .wrap {
  max-height: 200px;
  overflow-y: auto;
}

.fullHeight .wrap {
  height: 100%;
}

.spaced {
  padding: 20px;
}

.fullWidth {
  width: 100%;
}

.fullWidth .content {
  max-width: none;
}

.arrow {
  position: absolute;
  z-index: 2;
}

.rightStart .arrow,
.right .arrow,
.rightEnd .arrow {
  left: 0;
}

.leftStart .arrow,
.left .arrow,
.leftEnd .arrow {
  right: 0;
}

.topStart .arrow,
.top .arrow,
.topEnd .arrow {
  bottom: 0;
}

.bottomStart .arrow,
.bottom .arrow,
.bottomEnd .arrow {
  top: 0;
}

.error .content,
.error .arrow {
  background: var(--skinDanger);
}

/* Positioned on right from target */
.rightStart .content,
.right .content,
.rightEnd .content {
  margin-top: 0;
  margin-bottom: 0;
}

/* Positioned on left from target */
.leftStart .content,
.left .content,
.leftEnd .content {
  margin-top: 0;
  margin-bottom: 0;
}

/* Positioned on top from target */
.topStart .content,
.top .content,
.topEnd .content {
  margin-left: 0;
  margin-right: 0;
}

/* Positioned on bottom from target */
.bottomStart .content,
.bottom .content,
.bottomEnd .content {
  margin-left: 0;
  margin-right: 0;
}
