.bar {
  height: 10px;
  border-radius: var(--themeRadius);
  margin: 7px 0;
}

.small {
  margin: 4px 0;
}

.inline {
  /**
   * Since inline block doesn't squash margins
   * @see AbstractPlaceholder.css
   */
  margin: 0;
}

/* Add more margin to make equal height as standard input */
.input {
  padding: 15px 0;
  line-height: 0; /* Bar height fix, when placeholder is inline and inside Text component */
}

.inputBar {
  margin: 0;
}

/* Sizes from 1 up to 9 indicates width in percents */
.bar1 {
  width: 10%;
}

.bar2 {
  width: 20%;
}

.bar3 {
  width: 30%;
}

.bar4 {
  width: 40%;
}

.bar5 {
  width: 50%;
}

.bar6 {
  width: 60%;
}

.bar7 {
  width: 70%;
}

.bar8 {
  width: 80%;
}

.bar9 {
  width: 90%;
}

.bar10 {
  width: 100%;
}

/* Those styles indicates fixed sizes in pixels */
.bar40 {
  width: 40px; /* Imitating round button, that have 40px width */
}

.bar50 {
  width: 50px;
}

.bar100 {
  width: 100px;
}

.bar120 {
  width: 120px;
}

.bar150 {
  width: 150px;
}

.bar200 {
  width: 200px;
}
