/* this styles are minus because close button in content container but you can use padding  only for popover's wrapper component */
.close {
  position: absolute;
  right: -40px;
  top: -10px;
}

.popover {
  min-height: 60px;
  padding: 20px 60px 20px 20px;
}
