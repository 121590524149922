.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* 20px is padding-bottom on AppLayout_mainContent */
  height: calc(var(--appHeight) - var(--headerHeight) - var(--topWrapperHeight) - 20px);
}

@media screen and (min-width: 711px) {
  .wrapper {
    height: 100%;
    min-height: 480px;
  }
}
