.separator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 20px;
  min-height: 1px;
}

.separator::before,
.separator::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: var(--themeDivider);
}

.inverse.separator::before,
.inverse.separator::after {
  background: var(--themeDividerInverse);
}

/* Elements */
.content {
  padding: 0 10px;
  font: var(--themeTextSmall);
  line-height: 20px;
  text-align: center;
}

.separator.withOverlay,
.separator.withOverlay .content {
  background: transparent;
}

.separator.withOverlay::before,
.separator.withOverlay::after {
  display: none;
}
