.accordion {
  overflow: hidden;
}

.action {
  align-items: center;
  width: 100%;
  background-color: transparent; /* For overwriting browser-specific styles */
  border: none;
  cursor: pointer;
}

.heading {
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  height: 100%;
}

.headingWrap {
  display: flex;
  align-items: center;
  min-width: 0;
  height: 100%;
}

.title {
  text-align: left;
  min-width: 0;
  width: 100%;
  color: var(--themeTextColor);
  font: var(--themeH4);
}

.icon {
  margin-right: 20px;
}

.withArrow,
.withArrow .heading {
  display: flex;
}

.withArrow .title {
  width: auto;
}

.withArrow .headingWrap {
  padding-right: 10px;
}

.normal {
  padding: 0 20px;
}

.short {
  padding: 0 10px;
}
