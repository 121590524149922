.group {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  height: 100%;
}

.outOfWidth .group {
  overflow-y: auto;
  padding: 0 10px;
  margin-bottom: -1px;
  flex-grow: 1;
}

.outOfWidth .group::-webkit-scrollbar {
  display: none;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.fullsize {
  height: 100%;
}

.outOfWidth {
  position: relative;
  margin: 0 -10px;
  border-radius: inherit;
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), rgba(0, 0, 0, 0) 100%);
}

.outOfWidth.fullsize .group {
  height: calc(100% + 1px);
}

.showOverflow {
  mask-image: none;
}
