.content {
  position: relative;
  text-transform: none;
  font: var(--themeTextSmall);
  color: var(--themeTextColor);
  border-radius: var(--themeRadius);
}

.inverse {
  color: var(--themeTextColorInverse);
}

.content a {
  color: var(--themeTextColor);
  text-decoration: underline;
}

.inverse.content a {
  color: var(--themeTextColorInverse);
}

.content a:hover {
  text-decoration: none;
}

.content:empty {
  display: none;
}

.wrap {
  border-radius: inherit;
}

.fixedHeight {
  max-height: 200px;
  overflow-y: auto;
}

.spaced {
  padding: 7px 10px;
}

.fullWidth {
  width: 100%;
}

.fullWidth .content {
  max-width: none;
}

.arrow {
  position: absolute;
  z-index: -1; /* Hide triangle under content */
}

.error .content,
.error .arrow {
  background: var(--skinDanger);
}

/* Positioned on right from target */
.rightStart .content,
.right .content,
.rightEnd .content {
  margin-top: 0;
  margin-bottom: 0;
}

.rightStart .arrow,
.right .arrow,
.rightEnd .arrow {
  left: 0 /* rtl:ignore */;
}

/* Positioned on left from target */
.leftStart .content,
.left .content,
.leftEnd .content {
  margin-top: 0;
  margin-bottom: 0;
}

.leftStart .arrow,
.left .arrow,
.leftEnd .arrow {
  right: 0 /* rtl:ignore */;
}

/* Positioned on top from target */
.topStart .content,
.top .content,
.topEnd .content {
  margin-left: 0;
  margin-right: 0;
}

.topStart .arrow,
.top .arrow,
.topEnd .arrow {
  bottom: 0;
}

/* Positioned on bottom from target */
.bottomStart .content,
.bottom .content,
.bottomEnd .content {
  margin-left: 0;
  margin-right: 0;
}

.bottomStart .arrow,
.bottom .arrow,
.bottomEnd .arrow {
  top: 0;
}
