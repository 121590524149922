.slider {
  --sliderBulletSize: 20px;
  --barHeight: 4px;

  width: 100%;
  position: relative;
  padding-bottom: 20px; /* For values */
}

.wrap {
  position: relative;
  height: 20px;
}

.sliderBounds {
  position: relative;
  height: 100%;
  margin: 0 calc(var(--sliderBulletSize) / 2);
}

.bar {
  z-index: 1;
  height: var(--barHeight);
  background: var(--themeDivider);
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-radius: 2px;
}

.activeBar {
  composes: bar;
  background: var(--skinPrimary);
  z-index: 1;
}

.limitBar {
  composes: bar;
  background: var(--skinSuccess);
  z-index: 2;
}

.bar.limitBar {
  border-radius: 0;
}

.bar.activeBar {
  border-radius: 2px 0 0 2px;
}

.track {
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 0;
  z-index: 2;
}

.bullet {
  position: relative;
  top: calc(var(--barHeight) / 2);
  width: var(--sliderBulletSize);
  height: var(--sliderBulletSize);
  border-radius: var(--themeRadiusLarge);
  transform: translateX(-50%);
}

.value {
  position: absolute;
  top: calc(100% + var(--barHeight) / 2);
  left: 50%;
  transform: translateX(-50%);
}

.hidden {
  display: none;
}

/**
 * Correct display in case of range slider, since 2 bullets should not overlap each other
 */
.rangeSlider .sliderBounds {
  margin-right: calc(var(--sliderBulletSize) * 1.5);
}

.rangeSlider .track:last-child {
  /* For avoiding squashing of items when we select the same value */
  margin-left: var(--sliderBulletSize);
}

.rangeSlider .bar.activeBar {
  left: var(--sliderBulletSize);
  border-radius: 0;
}
