.wrap {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.wrap.wrapColumn {
  max-width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
}

.button {
  min-width: 200px;
}

.actionList {
  margin: auto;
  max-width: 410px;
}
