.title {
  color: var(--themeTextColor);
  margin: 0;
  padding: 0;
}

.title1 {
  font: var(--themeTitle1);
}

.title2 {
  font: var(--themeTitle2);
}

.title3 {
  font: var(--themeTitle3);
}

.title4 {
  font: var(--themeTitle4);
}

.success {
  color: var(--skinSuccess);
}

.primary {
  color: var(--skinPrimary);
}

.warning {
  color: var(--skinWarning);
}

.muted {
  color: var(--themeTextColorMuted);
}

.inverse {
  color: var(--themeTextColorInverse);
}

.uppercase {
  text-transform: uppercase;
}

.inverse.muted {
  color: var(--themeTextColorMutedInverse);
}

.inherit {
  color: inherit;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
