.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.icon {
  height: 20px; /* For the correct centering of the icon */
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 20px;
  transition: opacity var(--themeTransitionSpeed) var(--themeTransitionEase);
}

.check {
  composes: icon;
  margin: -2px 0 -2px 20px; /* Since icon is little bigger than content in select */
}

.hidden {
  opacity: 0;
}

.value {
  flex-grow: 1;
}

.cutedValue {
  composes: value;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
