.button {
  display: flex;
  position: relative;
  padding: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-align: center; /* Text is centered by default */
  text-decoration: none; /* Because it can be a link */
  border: none;
  justify-content: center;
  align-items: center;
  font: var(--themeH4);

  /*  Need to create new stacking context to prevent showing .icon and .text upper main context
  due-to using z-index see below
  .wrap {
    ...
    z-index: 2;
  https://developer.mozilla.org/ru/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
  */
  transform: translate3d(0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.transition {
  transition: var(--themeTransition);
}

/* Elements */
.icon {
  position: relative;
  height: 20px;
  font-size: 24px; /* Default size from brandbook */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  flex-shrink: 0;
}

.icon:last-child {
  padding-right: 0;
  padding-left: 10px;
}

.icon:only-child {
  padding-left: 0;
  padding-right: 0;
}

/**
 * Required in case if we use "iconWrapComponent" prop
 * for avoiding jumping during animation.
 */
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.text {
  text-align: center;
  overflow: hidden;
}

/**
 * Inner wrapper item.
 * Needed for correct displaying in FF browser for 'button' tag
 */
.wrap {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
  z-index: 2;
  min-width: 0;
}

.static {
  cursor: default;
}

/* Disabled state */
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.disabledInteraction {
  pointer-events: auto !important; /* stylelint-disable-line */
}

/* Default button */
.default {
  color: var(--themeTextColor);
  background: var(--themeButton);
}

.hoverable.default:active,
.hoverable.default:hover {
  background: var(--themeButtonHover);
}

.default .icon {
  color: var(--themeIcon);
}

/* Inverse default button */
.default.inverse {
  color: var(--themeTextColorInverse);
}

/* Primary button */
.primary {
  color: var(--themeTextColorInverse);
  background: var(--skinPrimary);
}

.hoverable.primary:active,
.hoverable.primary:hover {
  background: var(--skinPrimaryHover);
}

.primary .icon {
  color: var(--themeIconInverse);
}

/* Inverse primary button */
.primary.inverse {
  color: var(--themeTextColorInverse);
}

.primary.inverse .icon {
  color: var(--themeIconInverse);
}

/* Pay button */
.pay {
  color: var(--themeTextColorInverse);
  background: var(--skinSuccess);
}

.hoverable.pay:active,
.hoverable.pay:hover {
  background: var(--skinSuccessHover);
}

.pay .icon {
  color: var(--themeIconInverse);
}

/* Inverse pay button */
.pay.inverse {
  color: var(--themeTextColorInverse);
}

.pay.inverse .icon {
  color: var(--themeIconInverse);
}

/* FlatPay button */
.flatPay {
  color: var(--skinSuccess);
  background: transparent;
  box-shadow: none;
}

.hoverable.flatPay:active,
.hoverable.flatPay:hover {
  background: var(--themeCardLight);
}

/* Inverse flatPay button */
.flatPay.inverse {
  color: var(--skinSuccess);
}

.hoverable.flatPay.inverse:active,
.hoverable.flatPay.inverse:hover {
  background: var(--skinSuccessHover);
}

/* Danger button */
.danger {
  color: var(--themeTextColorInverse);
  background: var(--skinDanger);
}

.hoverable.danger:active,
.hoverable.danger:hover {
  background: var(--skinDangerHover);
}

.danger .icon {
  color: var(--themeIconInverse);
}

/* Flat button */
.flat {
  color: var(--themeTextColor);
  background: transparent;
  box-shadow: none;
}

.hoverable.flat:active,
.hoverable.flat:hover {
  background: var(--themeCardLight);
}

.flat .icon {
  color: var(--themeIcon);
}

/* Inverse flat button */
.flat.inverse {
  color: var(--themeTextColorInverse);
}

.hoverable.flat.inverse:active,
.hoverable.flat.inverse:hover {
  background: var(--themeCardLightInverse);
}

.flat.inverse .icon {
  color: var(--themeIconInverse);
}

/* Rounded button */
.round {
  width: 40px;
  height: 40px;

  /* Hide text */
  font-size: 0;
  line-height: 0;
}

.round .wrap {
  padding: 0;
}

.round .icon {
  padding: 0;

  /* Need to make icon on center on MAC Safari devices */
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.round .icon:first-child,
.round .icon:last-child {
  padding: 0;
}

/* Size modifications */
.small .wrap {
  padding-top: 3px;
  padding-bottom: 3px;
}

.big .wrap {
  padding: 15px;
}

/* Big round button */
.round.big {
  width: 60px;
  height: 60px;
}

.round.big .icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* Special modifications */
.textLeft {
  justify-content: flex-start;
}

.textLeft .text {
  text-align: left;
}

.textLeft .wrap {
  justify-content: flex-start;
}

.inline {
  display: inline-flex;
}

/**
 * For buttons, that must be NOT BIGGER than stadart BB button
 * WARNING: Don't use with size modifications
 */
.fixedHeight {
  height: 40px;
  line-height: normal;
}

.fixedHeight .wrap {
  padding-top: 2px;
  padding-bottom: 2px;
}

.fixedHeight .text {
  overflow: hidden; /* Needed to hide overflowing text from button */
  text-overflow: ellipsis;
  max-height: 36px;
  line-height: 18px;
}

/* Button that uses all available space */
.fullWidth {
  width: 100%;
}

@media screen and (min-width: 712px) {
  .adaptive.fullWidth {
    width: auto;
    min-width: 200px;
    margin: 0 auto;
  }
}

/* Loading state for button (Added via JS) */
.loading {
  opacity: 0.5;
  filter: grayscale(100%);
  pointer-events: none;
}

/* Narrow button (for e.g. used on search results) */
.narrow {
  font: var(--themeTextSmall);
  line-height: 1;
  text-transform: none;
  height: auto;
}

.narrow .icon {
  padding: 0;
}

.narrow .wrap {
  padding: 6px 4px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.withOverflow .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.narrow .text {
  margin-top: 4px;
  width: 100%;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);

  /**
   * By default, narrow button can contain only one-line text.
   * If you want another behavior - move this code to property of component.
   */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.inverse.narrow .text {
  color: var(--themeTextColorMutedInverse);
}
