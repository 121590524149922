.circle {
  border-radius: var(--themeRadiusLarge);
  width: 100%;
  height: 100%;
}

.circle20 {
  width: 20px;
  height: 20px;
}

.circle40 {
  width: 40px;
  height: 40px;
}

.circle60 {
  width: 60px;
  height: 60px;
}

.circle80 {
  width: 80px;
  height: 80px;
}

.circle120 {
  width: 120px;
  height: 120px;
}

.square {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  border-radius: inherit;
}

.square .circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
