/* stylelint-disable selector-max-specificity */
.row {
  position: relative;
}

.row:first-child,
.row.adaptive:first-child {
  margin-top: 0;
}

.row:last-child,
.row.adaptive:last-child {
  margin-bottom: 0;
}

.row:empty {
  display: none;
}

.minimal {
  margin: 2px 0;
}

.shortest {
  margin: 4px 0;
}

.short {
  margin: 10px 0;
}

.normal {
  margin: 20px 0;
}

.large {
  margin: 40px 0;
}

.largest {
  margin: 80px 0;
}

/**
 * Yep, for avoiding writing massive ternanry operators in react components,
 * we know, that all 'large' spacings must became 'normal'.
 */
@media (max-width: 767px) {
  .adaptive.large {
    margin: 20px 0;
  }

  .adaptive.largest {
    margin: 40px 0;
  }
}

/**
 * if item is child of flexbox.
 * Here we use 'half' of vertical spacing due to flexbox aligning of items
 * where margins doesn't collapse together.
 */
.flexibleWidth {
  flex-grow: 1;
  min-width: 0;
}

/**
 * Avoid content shrinking
 */
.noShrink {
  flex-shrink: 0;
}

.equalWidth {
  composes: flexibleWidth;
  flex-basis: 0;
}

.horizontal.shortest {
  margin: 0 4px 0 0; /* Requested from designers. Also it is used only if you want to add spacing between text, icons, etc. */
}

.horizontal.minimal {
  margin: 0 2px 2px 0;
}

.horizontal.minimal:last-child {
  margin: 0 0 2px 0;
}

.minimal.short {
  margin: 0 2px;
}

.horizontal.short {
  margin: 0 5px;
}

.horizontal.normal {
  margin: 0 10px;
}

.horizontal.large {
  margin: 0 20px;
}

.adaptive.horizontal.large {
  margin: 0 20px;
}

.horizontal.largest {
  margin: 0 40px;
}

.adaptive.horizontal.largest {
  margin: 0 40px;
}

.horizontal:not(.reverse).short:first-child,
.horizontal:not(.reverse).normal:first-child,
.horizontal:not(.reverse).large:first-child,
.adaptive.horizontal:not(.reverse).large:first-child {
  margin-left: 0;
}

.horizontal:not(.reverse).shortest:last-child,
.horizontal:not(.reverse).short:last-child,
.horizontal:not(.reverse).normal:last-child,
.horizontal:not(.reverse).large:last-child,
.adaptive.horizontal:not(.reverse).large:last-child {
  margin-right: 0;
}

.horizontal.reverse.short:first-child,
.horizontal.reverse.normal:first-child,
.horizontal.reverse.large:first-child,
.adaptive.horizontal.reverse.large:first-child {
  margin-right: 0;
}

.horizontal.reverse.shortest:last-child,
.horizontal.reverse.short:last-child,
.horizontal.reverse.normal:last-child,
.horizontal.reverse.large:last-child,
.adaptive.horizontal.reverse.large:last-child {
  margin-left: 0;
}

/* Alignment */
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
