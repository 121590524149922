.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value {
  flex-grow: 1;
  width: 100%;
  min-width: 0;
}

.cutedValue {
  composes: value;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag {
  margin-right: 20px;
}

/* Real select tag */
.input {
  /* Default select. Will be hidden */
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -5;
  border: none;

  /* Fix for Mac Safari. Area is not clickable. */
  -webkit-appearance: menulist-button;
}

.menu {
  /* Fix rendering on iPhone SE */
  transform: translateZ(0);
}

.clickable {
  z-index: 5;
}

.placeholder {
  color: var(--themeTextColorMuted);
  -webkit-text-fill-color: var(--themeTextColorMuted);
}
