.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  /* translateZ(0) is fix for render on iphone SE */
  transform: translateZ(0);
}
