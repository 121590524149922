.triangle {
  flex-shrink: 0;
  padding: 0 2px;
  margin: -2px 0 -2px 10px;
  will-change: transform;
  transition: transform var(--themeTransitionSpeed) var(--themeTransitionEase);
}

.top {
  transform: scale(1, -1);
}

.left {
  transform: rotate(-90deg);
}

.right {
  transform: rotate(-90deg) scale(1, -1);
}

.icon {
  vertical-align: middle;
}
