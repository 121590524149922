.container {
  opacity: 0; /* popper is hidden by default to make show/hide animation */
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  transition: opacity var(--themeTransitionSpeed) var(--themeTransitionEase);

  /* To stay visible in popups, in case of problems change it on modification 'inPopup'
   or try to detect inside popover than popup isOpen */
  z-index: 5001;
}

.fixed {
  position: fixed;
}

.container:not(.active) {
  pointer-events: none; /* to avoid unexpected interaction with the popper while it is hiding */
}

.active {
  opacity: 1;
}
