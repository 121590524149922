.vertical {
  position: relative;
}

.horizontal {
  composes: vertical;
  display: flex;
  flex-direction: row;
}

.horizontal.reverse {
  flex-direction: row-reverse;
}

.horizontal.verticalCenter {
  align-items: center;
}

.horizontal.verticalTop {
  align-items: flex-start;
}

.horizontal.verticalBottom {
  align-items: flex-end;
}

.forceInline {
  display: inline-flex;
}

.inlineItem {
  display: inline-block;
  vertical-align: middle;
}

.initial .inlineItem {
  vertical-align: initial;
}

.inlineItem:empty {
  display: none;
}

.bar {
  background: var(--themeDivider);
  width: 100%;
  height: 1px;
  flex-shrink: 0;
}

.horizontal .bar {
  width: 1px;
  height: 100%;
}

.horizontal .bar.inverse {
  min-height: 36px;
  background: var(--themeDividerInverse);
}

.fullHeight {
  height: 100%;
}
