.loader,
.overlay {
  position: relative;
  border-radius: inherit;
  height: inherit;
}

/**
 * If the size of the loader changes,
 * you need to adjust the size of the animation in 'fresh/loader/Loader.css'
 */
.static {
  min-width: 100px;
  height: 100px;
  overflow: hidden;
}

.static.small {
  min-width: 60px;
  height: 60px;
}

.static.medium {
  min-width: 80px;
  height: 80px;
}

.micro {
  height: 20px;
  width: 20px;
  min-width: 20px;
  overflow: unset;
}

.fullsize {
  width: 100%;
  height: 100%;
}

.fullscreen {
  composes: fullsize;
  position: fixed;
  top: 0;
  left: 0;
}

.fullscreen .overlay {
  background: var(--themeCard);
  opacity: 0.5;
}

/* Activate loader */
.active .overlay {
  opacity: 0.3;
  pointer-events: none;
}

.icon {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
}

.active .wrap {
  display: block;
}

/* Elements */
.wrap {
  display: none;
  text-align: center;
  position: absolute;
  z-index: 110;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

.fixed .wrap {
  position: fixed;
}

.text {
  color: var(--themeTextColor);
}

/* Modifications */
.inverse .text {
  color: var(--themeTextColorInverse);
}

.medium .icon {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.small .icon {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}

.micro .icon {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
}

.bottom.active .icon {
  top: auto;
  bottom: 16px;
}

.withText .icon {
  top: 0;
  left: 0;
  display: block;
  position: relative;
  margin: 0 auto 10px;
}
