.main {
  z-index: 9999;
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  pointer-events: none;
}

.item {
  padding: 10px;
  margin: 5px;
  background: lightcyan; /* Pop-up Load Start */
  color: black;
  font-weight: bold;
}

/* Pop-up Load Complete */
.Complete {
  background: greenyellow;
}

/* Page Load Complete */
.Page {
  background: lightgreen;
}
