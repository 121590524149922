/* Icons (standard size for font-icons etc.) */
.default {
  display: inline-block;
  -webkit-text-fill-color: currentColor; /* Discard fixes for iOS from InputWrapper.css */
  margin: -2px;
}

/* We have issue with vertical align in chrome for icons with specified parent height,
so we need override default align from top to bottom */
/* stylelint-disable */
.default::before {
  vertical-align: bottom;
}
/* stylelint-enable */

.clickable {
  cursor: pointer;
}

/* Modifications */
.standard {
  color: var(--themeIcon);
}

.inverse {
  color: var(--themeIconInverse);
}

.muted {
  color: var(--themeIconMuted);
}

.active,
.inverse.active {
  color: var(--skinPrimary);
}

.inverse.muted {
  color: var(--themeIconMutedInverse);
}

.success {
  color: var(--skinSuccess);
}

.danger {
  color: var(--skinDanger);
}

.warning {
  color: var(--skinWarning);
}

.accent {
  color: var(--skinAccent);
}

.disabled {
  color: var(--themeIconDisabled);
}

/* default size */
.size24 {
  font-size: 24px;
}

/* should be only after 'size24', for correct display if the icon is in a wrapper */
/* @see IconSubstrate */
.inherit {
  font-size: inherit;
}

.size12 {
  font-size: 12px;
  margin: -1px;
}

.size16 {
  font-size: 16px;
  margin: -1px;
}

.size36 {
  font-size: 36px;
  margin: -3px;
}

.size48 {
  font-size: 48px;
  margin: -4px;
}

.size72 {
  font-size: 72px;
  margin: -6px;
}

.size96 {
  font-size: 96px;
  margin: -8px;
}

[dir="rtl"] .mirrored {
  transform: scaleX(-1);
}
