.circle {
  stroke: var(--themeDivider);
  fill: none;
}

.shareCircle {
  composes: circle;
  transition: stroke-dashoffset 0.5s ease-out;
}

.rotate {
  animation: rotatefull 9s 0s infinite linear forwards;
}

.strokeWidth1 {
  stroke-width: 1;
}

.strokeWidth2 {
  stroke-width: 2;
}

/* Color modifications */
.inherit .shareCircle {
  stroke: inherit;
}

.primary .shareCircle {
  stroke: var(--skinPrimary);
}

.danger .shareCircle {
  stroke: var(--skinDanger);
}

.success .shareCircle {
  stroke: var(--skinSuccess);
}

.accent .shareCircle {
  stroke: var(--skinAccent);
}

.warning .shareCircle {
  stroke: var(--skinWarning);
}

.headerColor .shareCircle {
  stroke: var(--skinHeader);
}

.inverse .circle {
  stroke: var(--themeDividerInverse);
}

.light .circle {
  stroke: var(--themeDividerInverse);
}

.light .shareCircle {
  stroke: #fff;
}

@keyframes rotatefull {
  0% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(72deg);
  }

  40% {
    transform: rotate(144deg);
  }

  60% {
    transform: rotate(216deg);
  }

  80% {
    transform: rotate(288deg);
  }

  99%,
  100% {
    transform: rotate(360deg);
  }
}
