.text {
  font: var(--themeText);
}

.small {
  font: var(--themeTextSmall);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.normal {
  color: var(--themeTextColor);
}

.muted {
  color: var(--themeTextColorMuted);
}

.disabled {
  color: var(--themeTextColorDisabled);
}

.primary {
  color: var(--skinPrimary);
}

.placeholder {
  color: var(--themeTextColorDisabled);
}

.accent {
  color: var(--skinAccent);
}

.danger {
  color: var(--skinDanger);
}

.label {
  color: var(--themeTextColorLabel);
}

.success {
  color: var(--skinSuccess);
}

.warning {
  color: var(--skinWarning);
}

.bold {
  font-weight: bold;
}

.inline {
  display: inline;
}

.spaced {
  margin: 20px 0;
}

.small.spaced {
  margin: 10px 0;
}

.spaced:first-child {
  margin-top: 0;
}

.spaced:last-child {
  margin-bottom: 0;
}

.inherit {
  color: inherit;
}

.inverse {
  color: var(--themeTextColorInverse);
}

.inverse.muted {
  color: var(--themeTextColorMutedInverse);
}

.inverse.placeholder {
  color: var(--themeTextColorDisabledInverse);
}

.inverse.danger {
  color: var(--skinDangerInverse);
}

.inverse.success {
  color: var(--skinSuccessInverse);
}

.inverse.warning {
  color: var(--skinWarningInverse);
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.lineThrough {
  text-decoration: line-through;
}

.wordBreak {
  word-break: break-word;
}
