.container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.container::after,
.container::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  border-radius: 50%;
  background: transparent;
  will-change: transform;
}

.container::before {
  opacity: 0.25;
}

.container::after {
  z-index: 0;
  transition: var(--themeTransition);
}

/* States */
.hover.container::after {
  background: var(--themeCardLight);
  transform: scale(2);
}

.active.hover.container::after {
  background: var(--skinPrimaryLite);
}

/* Focused state */
/* stylelint-disable selector-max-specificity */
.focused.container::before {
  transform: scale(2);
  background: var(--themeTextColorLabel);
  transition: all 250ms var(--themeTransitionEase);
}

.active.focused.container::before {
  background: var(--skinPrimary);
}

/* Animated state */
.animated.container::before {
  background: var(--themeTextColorLabel);
  animation: wave 250ms linear forwards;
}

.animated.active.container::before {
  background: var(--skinPrimary);
}

/* Inverse state */
.inverse.animated.container::before,
.inverse.focused.container::before {
  background: var(--themeIconInverse);
}

.active.inverse.hover.container::after,
.inverse.hover.container::after {
  background: var(--themeCardLightInverse);
}
/* stylelint-enable */

@keyframes wave {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(2);
    opacity: 0.25;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
