.placeholder {
  background-color: var(--themeCardLight);
  width: 100%;
  flex-shrink: 0;
}

.animatedPlaceholder::after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: url(./animatedPlaceholder.gif);
  opacity: 0.075;
}

.inverse {
  background-color: var(--themeCardLightInverse);
}

.inline {
  /* Handle situations when we should align it to center without any additional styles */
  display: inline-block;
}
