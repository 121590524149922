.actions {
  display: flex;
  align-items: center;
}

.vertical {
  flex-direction: column;
}

.fullWidth {
  width: 100%;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.stretch {
  justify-content: stretch;
}

.between {
  justify-content: space-between;
}

.stretch .action {
  flex: 1 1 100%;
}
