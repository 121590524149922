.wrapper {
  height: inherit;
}

/**
 * Hidden state. Must be important because this class can be
 * mixed with other display properties. Needed only for BACKBONE popups
 * We use 'height:0' so that we don't pull out the element out of  DOM like 'display:none' does.
 * Because it makes it impossible to get the correct height inside the popup
 */
.hidden {
  height: 0 !important; /* stylelint-disable-line */
  overflow: hidden !important; /* stylelint-disable-line */

  /* Need to hide element inside popup which have different 'position' than value 'static/relative' */
  visibility: hidden !important; /* stylelint-disable-line */
  pointer-events: none;
}
